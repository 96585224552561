<template>
    <div class="w-full">
        <p>In progress...</p>
    </div>    
</template>
  
<script>
    export default {
        name: "SharedDocuments",
        components: {},
        data() {
            return {};
        },
        computed: {},
        methods: {},
    };
</script>

<style scoped>

</style>
    